.smr-default-layout {
  display: flex;
  // flex-direction: column;
  height: 100%;

  &__aside {
    padding: 16px;
    background-color: lightgray;
    height: 100vh;
  }

  &__logout {
    display: flex;
    justify-content: center;
  }

  &__content {
    padding: 16px;
    box-sizing: border-box;
    flex: 1;
  }
}
