@font-face {
  font-family: 'Roboto';
  src: url('../fonts/subset-Roboto-Regular.eot');
  src: url('../fonts/subset-Roboto-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/subset-Roboto-Regular.woff2') format('woff2'),
  url('../fonts/subset-Roboto-Regular.woff') format('woff'),
  url('../fonts/subset-Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/subset-Roboto-Medium.eot');
  src: url('../fonts/subset-Roboto-Medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/subset-Roboto-Medium.woff2') format('woff2'),
  url('../fonts/subset-Roboto-Medium.woff') format('woff'),
  url('../fonts/subset-Roboto-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/subset-Roboto-Thin.eot');
  src: url('../fonts/subset-Roboto-Thin.eot?#iefix') format('embedded-opentype'),
  url('../fonts/subset-Roboto-Thin.woff2') format('woff2'),
  url('../fonts/subset-Roboto-Thin.woff') format('woff'),
  url('../fonts/subset-Roboto-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/subset-Roboto-Light.eot');
  src: url('../fonts/subset-Roboto-Light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/subset-Roboto-Light.woff2') format('woff2'),
  url('../fonts/subset-Roboto-Light.woff') format('woff'),
  url('../fonts/subset-Roboto-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/subset-Roboto-Black.eot');
  src: url('../fonts/subset-Roboto-Black.eot?#iefix') format('embedded-opentype'),
  url('../fonts/subset-Roboto-Black.woff2') format('woff2'),
  url('../fonts/subset-Roboto-Black.woff') format('woff'),
  url('../fonts/subset-Roboto-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/subset-Roboto-Bold.eot');
  src: url('../fonts/subset-Roboto-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/subset-Roboto-Bold.woff2') format('woff2'),
  url('../fonts/subset-Roboto-Bold.woff') format('woff'),
  url('../fonts/subset-Roboto-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

